import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { CallToAction } from '../components/Sections/CallToAction'
import Search from '../components/Search/Search'
import { searchIndices } from '../utils/algolia-indices'

const BuscarPage = ({
  data: {
    directus: { page, layout },
  },
}: PageProps<Queries.BuscarPageQuery>) => {
  if (!page) {
    return null
  }

  return (
    <Layout search={false}>
      <div className="bg-brand-light-gray">
        <div className="container py-10 mb-20">
          <Search
            heading={page.search_title}
            placeholder={page.search_placeholder}
            noResultsText={page.search_no_results_text}
            indices={searchIndices}
          />
        </div>
      </div>
      {layout?.contact_banner && <CallToAction {...layout.contact_banner} />}
    </Layout>
  )
}

export default BuscarPage

export { PageHead as Head } from '../components/PageHead'

export const query = graphql`
  query BuscarPage {
    directus {
      page: page_buscar {
        meta_description
        page_title
        search_title
        search_placeholder
        search_no_results_text
      }
      layout {
        contact_banner {
          ...CallToActionComponent
        }
      }
    }
  }
`
